import { TextField } from '@mui/material';
import { motion } from 'framer-motion';
import React, { Fragment } from 'react';
import formLocalizedHelperText from 'utils/formLocalizedHelperText';
import { fadeUp } from 'utils/motion';

const FormTextField = ({
  delay = 0,
  duration = 0.5,
  formik,
  fieldName,
  label,
  type = 'text',
  variant = 'outlined',
  onKeyDown = () => {},
  readOnly = false,
  disabled = false,
  ...props
}) => {
  return (
    <Fragment>
      {formik && formik.values && formik.touched && formik.errors && (
        <TextField
          component={motion.div}
          variants={fadeUp(30, 'tween', delay, duration)}
          initial='hidden'
          animate='show'
          viewport={{ once: true, amount: 0.25 }}
          color='formColor'
          variant={variant}
          fullWidth
          id={fieldName}
          name={fieldName}
          label={label}
          type={type}
          error={formik.touched[fieldName] && Boolean(formik.errors[fieldName])}
          helperText={
            formik.touched[fieldName] &&
            formLocalizedHelperText(formik.errors[fieldName])
          }
          value={formik.values[fieldName]}
          onChange={(event) => {
            formik.setFieldValue(fieldName, event.target.value, true);
          }}
          onKeyDown={onKeyDown}
          InputProps={{
            readOnly: readOnly,
            disabled: disabled,
          }}
          autoComplete='off'
          {...props}
        />
      )}
    </Fragment>
  );
};

export default FormTextField;
