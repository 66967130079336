import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
import FilterAccordion from '../FilterAccordion';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import useSetMultiQuery from 'hooks/useSetMultiQuery';

const data = [
  { id: 'NO_ANSWER', name: 'Javobsiz' },
  { id: 'CONVERSATION', name: 'Gaplashilgan vaqt' },
  { id: 'USER_BUSY', name: 'Foydalanuvchi band' },
  { id: 'UNALLOCATED_NUMBER', name: "Mavjud bo'lmagan raqam" },
];

const CrmCallTypeFilter = ({ disabled = false }) => {
  const { t } = useTranslation();
  const setMultipleQueries = useSetMultiQuery();
  const [searchParams, setSearchParams] = useState([]);

  const handleRepairTypeChange = (value, checked) => {
    setSearchParams((prev) => {
      if (checked) {
        if (!prev.includes(value)) return [...prev, value];
      } else {
        if (prev?.includes(value)) {
          return prev.filter((item) => item !== value);
        }
      }
    });
  };

  useEffect(() => {
    return setMultipleQueries('statuses', searchParams);
  }, [searchParams]);

  return (
    <FilterAccordion title={"Qo'ng'iroq turi"} code='home-repair-type'>
      <FormControl fullWidth color='formColor' type='filterRadioGroup'>
        <FormGroup
          aria-labelledby='repair-type-radio-buttons-group'
          name='repair-type-radio-buttons-group-name'
          onChange={(event) =>
            handleRepairTypeChange(event.target.value, event.target.checked)
          }
        >
          {data &&
            data?.length &&
            data?.map((option) => (
              <div className='mb-1 w-full' key={option.id}>
                <FormControlLabel
                  className='w-full'
                  value={option.id}
                  control={<Checkbox />}
                  label={t(option.name)}
                  disabled={disabled}
                />
              </div>
            ))}
        </FormGroup>
      </FormControl>
    </FilterAccordion>
  );
};
export default CrmCallTypeFilter;
