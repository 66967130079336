/** @format */

export const initialState = {
  info: {},
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'setInfo':
      return { ...state, info: action.payload };
    default:
      return state;
  }
};
