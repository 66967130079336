import { Button, Drawer } from '@mui/material';
import DateTypeFilter from './items/dateTypeFilter';
import useMultipleQueries from 'hooks/useMultipleQueries';
import useSearch from 'hooks/useSearch';
import StaffFilter from '../ui/filters/items/StaffFilter';
import CrmColumnsFilter from './items/crmColumnsFilter';
import GenericRadiosFilter from './items/GenericRadiosFilter';
import BujutRangeFilter from './items/BujutRangeFilter';
import RegionFilter from './items/RegionFilter';

const leadTypeOptions = [
  {
    code: 'DELETED',
    label: `O'chirilgan lidlar`,
  },
  {
    code: 'ALL',
    label: 'Barcha lidlar',
  },
];

const TaskFilterDrawer = ({ open, setOpen, isLoading = false }) => {
  const setMultipleQueries = useMultipleQueries();
  const query = useSearch();

  const handleResetFiler = () => {
    setMultipleQueries({
      from: '',
      till: '',
      task_from: '',
      task_till: '',
      pipeStatusId: '',
      lead_status: '',
      created_by: '',
      user_id: '',
      butjet_from: '',
      butjet_till: '',
      region: '',
    });
  };

  return (
    <Drawer
      anchor='left'
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{
        sx: { padding: '18px', background: 'transparent', boxShadow: 'none' },
      }}
    >
      <div className='dashboard-filter-drawer-wrapper first-letter bg-white h-full rounded-lg border border-[#E5E9EB]'>
        <div className='mb-3 text-end'>
          <Button
            color='error'
            variant='outlined'
            size='small'
            disabled={isLoading}
            onClick={() => handleResetFiler()}
          >
            <i className='bi bi-arrow-repeat text-lg mr-1' />
            Tozalash
          </Button>
        </div>
        <DateTypeFilter disabled={isLoading} />
        <CrmColumnsFilter disabled={isLoading} />
        <DateTypeFilter
          disabled={isLoading}
          queries={['task_from', 'task_till']}
          label='Topshiriq: '
        />
        <GenericRadiosFilter
          disabled={isLoading}
          options={leadTypeOptions}
          queryName='lead_status'
          removeAll
          label='Lidlar holati'
          defaultValue={{ label: 'Faqat aktiv lidlar', code: 'all' }}
        />
        <BujutRangeFilter block={query.get('crm_block')} />
        <RegionFilter label='Hudud' block={query.get('crm_block')} />
        <StaffFilter
          disabled={isLoading}
          label='common.fields.staff'
          query='user_id'
        />
        <StaffFilter
          disabled={isLoading}
          label='common.fields.authorStaff'
          query='created_by'
        />
      </div>
    </Drawer>
  );
};
export default TaskFilterDrawer;
