import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Button, Drawer } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import DateFromToFilter from './items/dateFromToFilter';
import ContractStatusFilter from './items/GenericRadiosFilter';
import SearchInput from 'components/SearchInput';
import StaffFilter from '../ui/filters/items/StaffFilter';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import EventTypeFilter from '../ui/filters/items/EventTypeFilter';

const EventsFilterDrawer = ({ open, setOpen, isLoading = false }) => {
  const { i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const [hasErrorObjType, setHasErrorObjType] = useState(false);

  const handleResetFiler = () => {
    navigate(location.pathname, { replace: true });
  };

  const { data: objTypes, isLoading: isObjTypeLoading } = useQuery({
    queryKey: 'admin/event/types',
    queryFn: async function () {
      const response = await axiosPrivate.get('/admin/event/types');
      return response?.data?.data;
    },
    enabled: !hasErrorObjType,
    onError: () => {
      setHasErrorObjType(true);
    },
    retry: false,
  });

  const options = useMemo(
    () =>
      objTypes?.map((opt) => ({
        code: opt?.id,
        label: JSON.parse(opt?.name)?.[i18n.language],
      })),
    [objTypes, i18n.language]
  );

  return (
    <Drawer
      anchor='left'
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{
        sx: { padding: '18px', background: 'transparent', boxShadow: 'none' },
      }}
    >
      <div className='dashboard-filter-drawer-wrapper first-letter bg-white h-full rounded-lg border border-[#E5E9EB]'>
        <div className='mb-3 text-end'>
          <Button
            color='error'
            variant='outlined'
            size='small'
            disabled={isLoading}
            onClick={() => handleResetFiler()}
          >
            <i className='bi bi-arrow-repeat text-lg mr-1' />
            Tozalash
          </Button>
        </div>
        <DateFromToFilter disabled={isLoading} />
        <ContractStatusFilter
          options={options || []}
          disabled={isObjTypeLoading || hasErrorObjType}
          queryName='object_type'
          label='Obyekt turi'
        />
        <DateFromToFilter
          disabled={isLoading}
          label='Obyekt yaratilgan sana'
          queries={['createdFrom', 'createdTill']}
        />
        <EventTypeFilter />
        <StaffFilter
          disabled={isLoading}
          label='common.fields.staff'
          query='user_id'
        />
        <SearchInput inputKey='previous' label='Oldingi qiymat' />
        <SearchInput inputKey='next' label='Keyingi qiymat' />
      </div>
    </Drawer>
  );
};
export default EventsFilterDrawer;
