import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Box,
  Button,
  Chip,
  CircularProgress,
  Collapse,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
} from '@mui/material';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

const ObjectBlockMultiFilter = ({ size }) => {
  const axiosPrivate = useAxiosPrivate();
  const { t } = useTranslation();
  const [hasError, setHasError] = useState(false);
  const [selectedBlocks, setSelectedBlocks] = useState([]);
  const [selectedObjects, setSelectedObjects] = useState([]);
  const [objects, setObjects] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { isLoading, isFetching } = useQuery({
    queryKey: '/dictionary/objects3',
    queryFn: async function () {
      const response = await axiosPrivate.get('/dictionary/objects3');
      return response.data.data;
    },
    onSuccess: (data) => {
      setObjects(data);
    },
    enabled: !hasError,
    onError: (error) => {
      setHasError(true);
    },
    retry: false,
  });

  const clearAll = () => {
    const entries = Object.fromEntries(searchParams);
    Object.keys(entries).forEach((item) => {
      if (item.includes('block[') || item.includes('object['))
        searchParams.delete(item);
    });
  };

  const handleClearSelectBox = (e) => {
    e?.stopPropagation();
    clearAll();
    setSelectedBlocks([]);
    setSelectedObjects([]);
    setSearchParams(searchParams);
  };

  const onSelectObject = (e, id) => {
    e.stopPropagation();
    if (!selectedObjects?.includes(id)) {
      searchParams.set(`object[${id}]`, id);
      setSelectedObjects((ids) => [...ids, id]);
    } else {
      searchParams.delete(`object[${id}]`);

      setSelectedObjects((ids) => ids?.filter((val) => val !== id));
    }
    setSearchParams(searchParams);
  };
  const onSelectBlock = (e, id) => {
    e.stopPropagation();
    if (!selectedBlocks?.includes(id)) {
      searchParams.set(`block[${id}]`, id);
      setSelectedBlocks((ids) => [...ids, id]);
    } else {
      searchParams.delete(`block[${id}]`);

      setSelectedBlocks((ids) => ids?.filter((val) => val !== id));
    }
    setSearchParams(searchParams);
  };

  useEffect(() => {
    const entries = Object.fromEntries(searchParams);
    const objects = [];
    const blocks = [];
    Object.entries(entries).forEach(([key, value]) => {
      if (key.includes('block[')) blocks?.push(Number(value));
      if (key.includes('object[')) objects?.push(Number(value));
    });
    setSelectedObjects(objects);
    setSelectedBlocks(blocks);
  }, []);

  return (
    <FormControl fullWidth color='formColor'>
      <Button
        id='basic-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant='outlined'
        size={size}
        className='!base-border !rounded-md !text-gray-600 !justify-start !relative'
      >
        <div className='flex justify-start !max-w-[calc(100%-30px)] gap-2 relative !overflow-hidden !text-ellipsis !whitespace-nowrap !pr-[15px]'>
          {selectedBlocks?.length || selectedObjects?.length ? (
            <div className='px-2 rounded-full bg-green-600 text-white'>
              {(selectedBlocks?.length || 0) + (selectedObjects?.length || 0)}
            </div>
          ) : (
            ''
          )}
          {selectedBlocks?.length || selectedObjects?.length
            ? t('common.filter.selectedObjects')
            : t('common.filter.select')}
        </div>
        <IconButton
          onClick={(e) => handleClearSelectBox(e)}
          size='small'
          variant='onlyIcon'
          sx={{
            display:
              selectedBlocks.length + selectedObjects.length > 0 ? '' : 'none',
          }}
          className='select-box-end-adornment-button !absolute !top-1/2 !right-4 !-translate-y-1/2 !cursor-pointer'
        >
          <i className='bi bi-x end-adornment-close-button' />
        </IconButton>
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className='!shadow-none'
        PaperProps={{
          sx: { marginTop: '4px', width: '300px' },
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          sx: { padding: 0 },
        }}
      >
        {isLoading || isFetching ? (
          <div className='circular-progress-box'>
            <CircularProgress size={25} />
          </div>
        ) : objects && objects.length > 0 ? (
          objects.map((item, index) => (
            <Accordion className='!shadow-none !p-0 !m-0'>
              <AccordionSummary
                className='flex-row-reverse  !p-0 !m-0'
                expandIcon={<i className='bi bi-caret-down-fill !px-3' />}
                onClick={(e) => e.stopPropagation()}
                aria-controls='panel1-content'
                id='panel1-header'
              >
                <MenuItem
                  value={item?.id}
                  key={`object-${index + 1}`}
                  fullWidth
                  className={`!w-full !h-full !m-0 !text-[12px] !normal-case  ${
                    selectedObjects?.includes(item?.id) && '!bg-green-300'
                  }`}
                  onClick={(e) => onSelectObject(e, item?.id)}
                >
                  {item?.name}
                </MenuItem>
              </AccordionSummary>
              <AccordionDetails className='!p-0 !pl-14'>
                {item?.blocks?.map((block) => {
                  return (
                    <MenuItem
                      key={`block-${index}-${block.id}`}
                      className={` !text-[12px] !normal-case  ${
                        selectedBlocks?.includes(block?.id) && '!bg-green-300'
                      }`}
                      value={block?.id}
                      onClick={(e) => onSelectBlock(e, block?.id)}
                    >
                      {block?.name}
                    </MenuItem>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          ))
        ) : (
          <div>
            <span className='no-data-found-wrapper select-box'>
              <i className='bi bi-exclamation-octagon text-lg mr-1' />{' '}
              {t('common.global.noDataFound')}
            </span>
          </div>
        )}
      </Menu>
    </FormControl>
  );
};

export default ObjectBlockMultiFilter;
