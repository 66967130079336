import {
  Button,
  CircularProgress,
  Divider,
  FormControlLabel,
  Switch,
} from '@mui/material';
import React, { memo, useState } from 'react';
import FormSimpleSelectField from 'components/ui/form/FormSimpleSelectField';
import ModalNewFieldMaker from './ModalNewFieldMaker';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import { useQuery } from 'react-query';

export default memo(function SettingsSection({ formik }) {
  const [openModal, setOpenModal] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const [hasError, setHasError] = useState(false);
  const [selected, setSelected] = useState({});
  const { data, isLoading, isFetching, refetch } = useQuery({
    queryKey: '/crm/crm-settings/fields',
    queryFn: async function () {
      const response = await axiosPrivate.get('/crm/crm-settings/fields');
      return response.data.data;
    },
    enabled: !hasError,
    onError: (error) => {
      setHasError(true);
    },
    retry: false,
  });
  const onDelete = (id) => {
    // console.log(id);
  };
  return (
    <div className='mt-2'>
      <div className='mb-4'>
        <p className='text-sm font-medium mb-1'>
          Maydon va guruh xususiyatlarini sozlash
        </p>
        <span className='text-gray-400 text-sm'>
          Bu yerda siz o'zingizning noyob maydonlaringizni qo'shishingiz va
          ularni har qanday filtr va hisobotlar uchun ishlatishingiz mumkun
        </span>
        <p className='text-sm font-medium my-1'>Asosiy ma'lumot</p>
        <Divider />
      </div>
      <div className='mb-4'>
        <div className='item-value'>
          <FormControlLabel
            value='start'
            control={<Switch color='primary' />}
            label="Topshiriqsiz lidni yangi etapga olib o'tishni cheklash"
            labelPlacement='start'
            sx={{
              width: '100%',
              justifyContent: 'space-between',
              marginLeft: 1,
            }}
          />
          <FormSimpleSelectField
            delay={0}
            duration={0}
            fieldName='crm_block_id'
            label=''
            formik={formik}
            disabled
            isItemSizeSmall={true}
          />
        </div>
        <div className='item-value'>
          <FormControlLabel
            value='start'
            control={<Switch color='primary' />}
            label="Topshiriqsiz lid mas'ullarini ogohlantirish"
            labelPlacement='start'
            sx={{
              width: '100%',
              justifyContent: 'space-between',
              marginLeft: 1,
            }}
          />
          <FormSimpleSelectField
            delay={0}
            duration={0}
            fieldName='crm_block_id'
            label=''
            formik={formik}
            disabled
            isItemSizeSmall={true}
          />
        </div>
        <Divider />
      </div>
      <div className='mb-4'>
        {isLoading || isFetching ? (
          <div className='circular-progress-box my-4'>
            <CircularProgress size={25} />
          </div>
        ) : (
          data?.map((field, i) => (
            <div
              key={i}
              className='item-value text-sm mb-2 grid grid-cols-[1fr,0.8fr,84px] border p-1 items-center pl-3 rounded-md'
            >
              <span className='text-gray-400'>{field?.name}</span>
              <span>
                {field?.type === 'NUMBER'
                  ? 0
                  : field?.type === 'SELECT'
                  ? field?.options?.length + '-tanlov'
                  : 'matn'}
              </span>
              <div className='flex gap-[2px]'>
                <Button
                  size='small'
                  variant='contained'
                  color='action'
                  sx={{ minWidth: 40 }}
                  onClick={() => {
                    setOpenModal(true);
                    setSelected(field);
                  }}
                >
                  <i className='bi bi-pen' />
                </Button>
                <Button
                  size='small'
                  variant='contained'
                  color='action'
                  sx={{
                    minWidth: 40,
                    background: 'rgba(255,0,0,0.01)',
                    border: '1px solid rgba(255,0,0,0.3)',
                  }}
                  onClick={() => onDelete(field?.id)}
                >
                  <i className='bi bi-trash3 text-red-500' />
                </Button>
              </div>
            </div>
          ))
        )}
        <div className='item-value mb-2'>
          <Button
            startIcon={<i className='bi bi-plus' />}
            size='small'
            variant='outlined'
            className='!text-gray-400 hover:!text-black'
            sx={{ borderStyle: 'dashed' }}
            onClick={() => setOpenModal(true)}
          >
            Yangi qo'shish
          </Button>
        </div>
        <Divider />
      </div>
      {openModal && (
        <ModalNewFieldMaker
          open={openModal}
          setOpen={setOpenModal}
          refetch={refetch}
          selected={selected}
          setSelected={setSelected}
        />
      )}
    </div>
  );
});
