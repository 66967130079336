import { Button } from '@mui/material';
import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';

export default memo(function Navigators() {
  const navigate = useNavigate();

  return (
    <div className='flex gap-[10px]'>
      <Button
        onClick={() => navigate(-1)}
        size='small'
        variant='outlined'
        sx={{ minWidth: 30 }}
        disabled={window.history.state.idx == 0}
      >
        <i className='bi bi-arrow-left-short !text-lg !leading-[1.8rem]' />
      </Button>
      <Button
        onClick={() => navigate(1)}
        size='small'
        variant='outlined'
        sx={{ minWidth: 30 }}
        disabled={window.history.state.idx == window.history.length - 1}
      >
        <i className='bi bi-arrow-right-short !text-lg !leading-[1.8rem]' />
      </Button>
    </div>
  );
});
