import React, { memo, useEffect, useRef, useState } from 'react';
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardMedia,
  CircularProgress,
  Grid,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { motion } from 'framer-motion';

import useTopPanel from 'hooks/useTopPanel';
import uploadImage from 'assets/images/upload-image.png';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import FormTextField from 'components/ui/form/FormTextField';
import useFormSubmit from 'hooks/useFormSubmit';
import useNotification from 'hooks/useNotification';

const validationSchema = yup.object({
  name: yup.string().required('settings.interface.validation.name'),
  phone: yup.string().required('settings.interface.validation.name'),
  facebook: yup.string(),
  instagram: yup.string(),
  telegram: yup.string(),
});

const Interface = () => {
  const fileInput = useRef(null);
  const { setComponent } = useTopPanel();
  const { t, i18n } = useTranslation();
  const [file, setFile] = useState(uploadImage);
  const axiosPrivate = useAxiosPrivate();
  const { submit, isSubmitting } = useFormSubmit();
  const { REACT_APP_BACKEND_URL } = process.env;
  const sendNotification = useNotification();

  const formik = useFormik({
    initialValues: {
      name: '',
      phone: '',
      image: '',
      facebook: '',
      instagram: '',
      telegram: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (typeof values?.image === 'string') {
        delete values.image;
      }
      if (!file) {
        if (
          !document
            .getElementById('card-image')
            .classList.contains('!border-[#f00]')
        )
          document.getElementById('card-image').classList.add('!border-[#f00]');
        sendNotification({
          msg: t('settings.interface.validation.image'),
          variant: 'error',
        });
        return;
      }
      submit(
        { type: 'post', contentType: 'formData' },
        values,
        '/admin/interface/update',
        values.name,
        null,
        true,
        refetch
      );
    },
  });

  useEffect(() => {
    setComponent(
      <div className='component-title'>{t('settings.interface.title')}</div>
    );
  }, [i18n.language, setComponent, t]);

  const { isLoading, isFetching, data, refetch } = useQuery({
    queryKey: '/interface',
    queryFn: async function () {
      const response = await axiosPrivate.get('/interface');
      return response.data;
    },
    onSuccess: (res) => {
      if (data?.status) {
        const { data } = res;
        document.title = data?.name;

        formik.setValues({
          name: data?.name || '',
          phone: data?.phone || '',
          facebook: data?.facebook || '',
          instagram: data?.instagram || '',
          telegram: data?.telegram || '',
          image: data?.image || '',
        });
      }
    },
    // onError: (error) => {
    // 	setHasError(true)
    // },
    retry: false,
  });

  useEffect(() => {
    formik.setValues(
      {
        name: data?.data?.name || '',
        phone: data?.data?.phone || '',
        facebook: data?.data?.facebook || '',
        instagram: data?.data?.instagram || '',
        telegram: data?.data?.telegram || '',
        image: data?.data?.image || '',
      },
      false
    );
    setFile(`${REACT_APP_BACKEND_URL}/${data?.data?.image}`);
  }, [data]);

  const onChange = async (e) => {
    const file = e.target.files[0];
    if (
      document.getElementById('card-image').classList.contains('!border-[#f00]')
    )
      document.getElementById('card-image').classList.remove('!border-[#f00]');

    if (file) {
      const fileReader = new FileReader();
      fileReader.onload = function (event) {
        setFile(event.target.result);
        formik.setFieldValue('image', file, false);
      };
      fileReader.readAsDataURL(file);
    }
  };

  return (
    <div className='component-list-wrapper bg-white base-border my-scroll overflow-y-auto p-6'>
      {isLoading || isFetching ? (
        <div className='circular-progress-box py-5'>
          <CircularProgress size={35} />
        </div>
      ) : (
        <Grid
          className='setting-wrapper'
          spacing={{ xs: 2, sm: 2, lg: 2 }}
          columns={{ xs: 12, sm: 12, lg: 12 }}
        >
          <Grid item xs={12}>
            <div className='flex justify-center'>
              <Card
                sx={{
                  maxWidth: 400,
                  boxShadow: 'none',
                  minWidth: 250,
                }}
                className='!base-border'
                id={'card-image'}
              >
                <CardActionArea sx={{ minHeight: 250 }}>
                  <CardMedia
                    component='img'
                    height='140'
                    image={file}
                    alt='logo'
                  />
                </CardActionArea>
                <CardActions className='place-content-center !relative'>
                  <input
                    type='file'
                    className='opacity-0 absolute inset-0 -z-15'
                    accept='.jpeg, .jpg, .gif, .png, .bmp, .svg'
                    ref={fileInput}
                    onChange={onChange}
                  />
                  <Button
                    variant='outlined'
                    color='success'
                    className='!static'
                    onClick={() => fileInput.current.click()}
                    fullWidth
                  >
                    <i className={'bi bi-pencil mr-2'} />
                    {data?.data?.image
                      ? t('common.button.edit')
                      : t('common.button.imageUpload')}
                  </Button>
                </CardActions>
              </Card>
            </div>
          </Grid>
          <div className='mt-5'>
            <form onSubmit={formik.handleSubmit}>
              <div className='max-w-[720px] mx-auto base-border p-5'>
                <Grid
                  container
                  spacing={{ xs: 2, sm: 3, lg: 3 }}
                  rowSpacing={1}
                  columns={{ xs: 12, sm: 12, lg: 12 }}
                >
                  <Grid item={true} lg={12} sm={12} xs={12}>
                    <FormTextField
                      delay={0.1}
                      label={t('common.fields.simpleName')}
                      fieldName='name'
                      formik={formik}
                    />
                  </Grid>

                  <Grid item={true} lg={6} sm={6} xs={12}>
                    <FormTextField
                      delay={0.2}
                      label={t('common.fields.phone')}
                      fieldName='phone'
                      formik={formik}
                    />
                  </Grid>

                  <Grid item={true} lg={6} sm={6} xs={12}>
                    <FormTextField
                      delay={0.2}
                      label={'facebook'}
                      fieldName='facebook'
                      formik={formik}
                    />
                  </Grid>
                  <Grid item={true} lg={6} sm={6} xs={12}>
                    <FormTextField
                      delay={0.3}
                      label={'instagram'}
                      fieldName='instagram'
                      formik={formik}
                    />
                  </Grid>
                  <Grid item={true} lg={6} sm={6} xs={12}>
                    <FormTextField
                      delay={0.3}
                      label={'telegram'}
                      fieldName='telegram'
                      formik={formik}
                    />
                  </Grid>
                </Grid>
              </div>
              {Object.values(formik.values)?.some((val) => val) ? (
                <motion.div
                  className='flex justify-center mt-5'
                  initial={{ opacity: 0, scale: 0.9, y: 20 }}
                  animate={{ opacity: 1, scale: 1, y: 0 }}
                  transition={{ duration: 0.1 }}
                >
                  <Button
                    variant='contained'
                    color='info'
                    className='!w-[200px]'
                    type='submit'
                  >
                    {isSubmitting ? (
                      <i className='bi bi-arrow-clockwise spin' />
                    ) : null}
                    {t('common.button.save')}
                  </Button>
                </motion.div>
              ) : null}
            </form>
          </div>
        </Grid>
      )}
    </div>
  );
};

export default memo(Interface);
